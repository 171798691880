<template>
  <div class="calc-score-container" :style="style">
    <div class="calc-score-title">计分</div>

    <div class="calc-score-content">
      <div v-for="(item, itemIndex) in calcScoreArr" :key="`calc-score-${itemIndex}`">
        <div class="table-number-text">{{ `${itemIndex + 1}号桌` }}</div>
        <div class="table-score-text">{{ `${item.score}分` }}</div>
        <div>
          <el-button type="success" icon="el-icon-plus" circle @click="bonusPoint(itemIndex)" />
          <el-button type="danger" icon="el-icon-minus" circle @click="reducePoint(itemIndex)" />
          <el-button type="warning" icon="el-icon-refresh-right" circle @click="resetPoint(itemIndex)" />
        </div>
      </div>
    </div>

    <div class="calc-score-reset-box">
      <el-input-number v-model="resetScore" :min="0" label="重置分数" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'CalcScore',

  props: {
    style: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      calcScoreArr: [
        {
          score: 0,
        },
        {
          score: 0,
        },
        {
          score: 0,
        },
        {
          score: 0,
        },
        {
          score: 0,
        },
      ],
      resetScore: 0,
    }
  },

  methods: {
    // 加分
    bonusPoint(index) {
      const that = this
      that.calcScoreArr[index].score += 1
    },

    // 减分
    reducePoint(index) {
      const that = this
      that.calcScoreArr[index].score -= 1
    },

    // 重置分数
    resetPoint(index) {
      const that = this
      that.calcScoreArr[index].score = that.resetScore
    },
  }
}
</script>

<style scoped>
.calc-score-container {
  font-family: serif;
  background-image: url('../../assets/images/paint.png');
  background-repeat: no-repeat;
  background-size: calc(100% + 35px) calc(100% + 42px);
  background-position: center;
  padding: 70px 6% 10px 6%;
}
.calc-score-title {
  font-size: 42px;
  color: #FFD86D;
  margin: 10px 0;
}
.calc-score-content {
  display: flex;
  justify-content: space-between;
}
.calc-score-reset-box {
  margin-top: 30px;
}
.table-number-text {
  color: #FFD86D;
  font-size: 56px;
}
.table-score-text {
  color: #FFD86D;
  font-size: 56px;
}
</style>
