/**
 * 补零
 * @param {(string|number)} value
 * @param {number} length
 * @returns {string}
 */
export function zeroPad(value, length) {
  let str = value.toString();
  
  while (str.length < length) {
    str = '0' + str;
  }
  
  return str;
}
