<template>
  <div class="">
    <div class="container-title">
      <div class="title-first-layer">
        <div class="title-second-layer">
          <div class="title-third-layer">
            <div class="title-text">{{ name }}</div>
          </div>
        </div>
      </div>
    </div>
    
    <div v-if="ruleDetailVisible" class="container-content">
      <div class="content-box">
        <div class="content-title">游戏规则</div>
        <div class="content-text">{{ ruleDetail }}</div>
      </div>

      <div v-if="gameContent != null" style="width: fit-content; margin: 10px auto;">
        <el-link type="primary" @click="ruleDetailVisible = false">游戏内容</el-link>
      </div>
    </div>

    <div v-else class="container-content">
      <div class="content-box">
        <div class="content-title">游戏内容</div>
        <div class="content-text">
          <!-- 游戏内容 -->
          <div v-if="gameContent != null" style="text-align: center;">
            <el-button 
              v-for="(subItem, subItemIndex) in gameContent"
              :key="`sub-item-${subItemIndex}`"
              :type="selectSubIndex === subItemIndex ? 'info' : 'primary'"
              :disabled="selectSubIndex === subItemIndex"
              circle
              @click="selectSubIndex = subItemIndex"
            >
              <div>{{ zeroPad(subItemIndex + 1, 2) }}</div>
            </el-button>
          </div>

          <!-- 默认 -->
          <div v-if="type == 'default'" class="content-describe" style="margin-top: 10px;">
            {{ gameContent[selectSubIndex] }}
          </div>

          <!-- 大字展示 -->
          <div v-else-if="type == 'bigFont'" class="content-describe" style="font-size: 240px; text-align: center;">
            {{ gameContent[selectSubIndex] }}
          </div>

          <!-- 音乐 -->
          <div v-else-if="type == 'music'" class="content-describe" style="text-align: center;">
            <div style="color: #FFD86D;">
              <span>
                <span v-if="gameContent[selectSubIndex].titleVisible">{{ gameContent[selectSubIndex].realTitle }}</span>
                <span v-else>{{ gameContent[selectSubIndex].title }}</span>
                <i class="el-icon-view" style="margin-left: 20px; color: #999999;" @click="gameContent[selectSubIndex].titleVisible = !gameContent[selectSubIndex].titleVisible" />
              </span>
            </div>

            <div
              v-for="(audioItem, audioIndex) in gameContent[selectSubIndex].paths"
              :key="`audio_${selectSubIndex}_para_${audioIndex}`"
              style="display: flex; justify-content: center; margin: 10px 0;"
            >
              <div style="color: #FFD86D; padding-right: 20px; font-size: 24px; line-height: 54px;">
                {{ `片段${zeroPad(audioIndex + 1, 2)}` }}
              </div>
              <audio :src="audioItem" controls controlsList="nodownload"/>
            </div>

            <calc-score style="margin-top: 20px;" />
          </div>
        </div>
      </div>

      <div v-if="gameContent != null" style="width: fit-content; margin: 10px auto;">
        <el-link type="primary" @click="ruleDetailVisible = true">游戏规则</el-link>
      </div>
    </div>
  </div>
</template>

<script>
import calcScore from '@/components/calcScore'
import { zeroPad } from '@/utils/index'

export default {
  name: 'RuleDetail',

  components: { calcScore },

  props: {
    name: {
      type: String,
      required: true,
    },
    ruleDetail: {
      type: String,
      required: true,
    },
    gameContent: {
      type: Array,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: 'default',
    },
  },

  data() {
    return {
      ruleDetailVisible: true,
      selectSubIndex: 0,
      calcScoreArr: [
        {
          score: 0,
        },
        {
          score: 0,
        },
        {
          score: 0,
        },
        {
          score: 0,
        },
        {
          score: 0,
        },
      ],
    }
  },

  methods: {
    zeroPad,
  }
}
</script>

<style scoped>
.background {
  min-height: 100vh;
  width: 100%;
  background-image: url('../../assets/images/background_2024.jpg');
  background-repeat: no-repeat;
  background-size: 100vw 100%;
  background-position: center top;
  margin: 0px;
  padding: 0px 0px 20px 0px;
}

.container-title {
  padding: 0px 0px 20px 0px;
}

.title-first-layer {
  width: 420px;
  margin: 0px auto;
  border: 8px solid #FFD86D;
  padding: 6px;
}

.title-second-layer {
  border: 2px solid #FFD86D;
  padding: 8px;
}

.title-third-layer {
  background-color: #D92411;
}

.title-text {
  font-family: cursive;
  font-size: 64px;
  font-weight: 600;
  color: #FFD86D;
  letter-spacing: 10px;
  text-align: center;
  text-shadow: 2px 2px #000000;
}

.container-content {
  padding: 0px;
}

.content-box {
  width: 50vw;
  min-width: 100px;
  min-height: 200px;
  border: 3px solid #FFD86D;
  border-radius: 20px;
  padding: 20px;
  margin: 0px auto;
}

.content-title {
  font-family: cursive;
  font-size: 42px;
  font-weight: 600;
  color: #FFD86D;
  letter-spacing: 6px;
  text-align: center;
}

.content-text {
  font-family: cursive;
  font-size: 30px;
  color: #FFD86D;
  letter-spacing: 3px;
  overflow-wrap: break-word;
  white-space: pre-line;
}

.content-describe {
  min-height: 200px;
  margin-top: 10px;
  padding: 20px;
  margin: 0px auto;
  font-family: cursive;
  font-size: 30px;
  color: #000000;
  letter-spacing: 2px;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

/* 定义滚动条样式 */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
</style>
