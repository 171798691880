<template>
  <div class="background">
    <div class="container">
      <el-tabs tab-position="left">
        <el-tab-pane v-for="(item, index) in ruleData" :key="`rule_detail_${index}`">
          <div slot="label">
            <div class="label-first-layer">
              <div class="label-second-layer">
                <div class="label-third-layer">
                  <div class="label-text">{{ item.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <rule-detail
            :name="item.name"
            :ruleDetail="item.detail"
            :gameContent="item.content"
            :type="item.type"
          />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import ruleDetail from '@/components/ruleDetail'

export default {
  name: 'Rule',
  components: { ruleDetail },
  data() {
    return {
      ruleData: [
        {
          name: '正话反说',
          detail: '人数：不限 \n 规则：主持人将随机挑选挑战者，主持人说出一个词，挑战者需要1秒内将这个词反着说出，未能成功者将要接受“严峻”的惩罚。(｀∀´)Ψ'
        },
        {
          name: '定时闹钟',
          detail: '人数：全体 \n 规则：主持人会提出一个多种答案的问题（如：水果有哪些？），并设置一个倒计时，开始之后将“炸弹”给到玩家，玩家需要回答出一个正确答案后才可以把闹钟交给下一名玩家。这样逐个传递，直到“炸弹”爆炸。被“炸弹”炸到的玩家将要接受严峻的惩罚。(屮｀∀´)屮'
        },
        {
          name: '听歌识曲',
          detail: '人数：全体 \n 每桌玩家为一个队伍。主持人将播放音乐片段（一首歌会提供两个片段，首先会播放第一个片段，未猜出时会播放第二个片段），所有玩家可以进行抢答。答对玩家的队伍加一分，最终分数最高的队伍获胜并领取奖励。o(｀ω´*)o \n 奖励：纸抽/牙膏/毛巾',
          type: 'music',
          content: [
            { title: '曲目1', realTitle: '甜蜜蜜', titleVisible: false, paths: [require('@/assets/audio/music/甜蜜蜜/1.mp3'), require('@/assets/audio/music/甜蜜蜜/2.mp3')] },
            { title: '曲目2', realTitle: '光辉岁月', titleVisible: false, paths: [require('@/assets/audio/music/光辉岁月/1.mp3'), require('@/assets/audio/music/光辉岁月/2.mp3')] },
            { title: '曲目3', realTitle: '爱如火', titleVisible: false, paths: [require('@/assets/audio/music/爱如火/1.mp3'), require('@/assets/audio/music/爱如火/2.mp3')] },
            { title: '曲目4', realTitle: '青藏高原', titleVisible: false, paths: [require('@/assets/audio/music/青藏高原/1.mp3'), require('@/assets/audio/music/青藏高原/2.mp3')] },
            { title: '曲目5', realTitle: '套马杆', titleVisible: false, paths: [require('@/assets/audio/music/套马杆/1.mp3'), require('@/assets/audio/music/套马杆/2.mp3')] },
            { title: '曲目6', realTitle: '乌梅子酱', titleVisible: false, paths: [require('@/assets/audio/music/乌梅子酱/1.mp3'), require('@/assets/audio/music/乌梅子酱/2.mp3')] },
            { title: '曲目7', realTitle: '倩女幽魂', titleVisible: false, paths: [require('@/assets/audio/music/倩女幽魂/1.mp3'), require('@/assets/audio/music/倩女幽魂/2.mp3')] },
            { title: '曲目8', realTitle: '爱如潮水', titleVisible: false, paths: [require('@/assets/audio/music/爱如潮水/1.mp3'), require('@/assets/audio/music/爱如潮水/2.mp3')] },
            { title: '曲目9', realTitle: '往后余生', titleVisible: false, paths: [require('@/assets/audio/music/往后余生/1.mp3'), require('@/assets/audio/music/往后余生/2.mp3')] },
            { title: '曲目10', realTitle: '如愿', titleVisible: false, paths: [require('@/assets/audio/music/如愿/1.mp3'), require('@/assets/audio/music/如愿/2.mp3')] },
            { title: '曲目11', realTitle: '天路', titleVisible: false, paths: [require('@/assets/audio/music/天路/1.mp3'), require('@/assets/audio/music/天路/2.mp3')] },
            { title: '曲目12', realTitle: '世间只有你最好/世间始终你好', titleVisible: false, paths: [require('@/assets/audio/music/世间始终你好/1.mp3'), require('@/assets/audio/music/世间始终你好/2.mp3')] },
            { title: '曲目13', realTitle: '千千阕歌', titleVisible: false, paths: [require('@/assets/audio/music/千千阕歌/1.mp3'), require('@/assets/audio/music/千千阕歌/2.mp3')] },
            { title: '曲目14', realTitle: '暗香', titleVisible: false, paths: [require('@/assets/audio/music/暗香/1.mp3'), require('@/assets/audio/music/暗香/2.mp3')] },
            { title: '曲目15', realTitle: '缘分一道桥', titleVisible: false, paths: [require('@/assets/audio/music/缘分一道桥/1.mp3'), require('@/assets/audio/music/缘分一道桥/2.mp3')] },
            { title: '曲目16', realTitle: '我和草原有个约定', titleVisible: false, paths: [require('@/assets/audio/music/我和草原有个约定/1.mp3'), require('@/assets/audio/music/我和草原有个约定/2.mp3')] },
            { title: '曲目17', realTitle: '单身情歌', titleVisible: false, paths: [require('@/assets/audio/music/单身情歌/1.mp3'), require('@/assets/audio/music/单身情歌/2.mp3')] },
            { title: '曲目18', realTitle: '想你时风起', titleVisible: false, paths: [require('@/assets/audio/music/想你时风起/1.mp3'), require('@/assets/audio/music/想你时风起/2.mp3')] },
            { title: '曲目19', realTitle: '美丽的草原我的家', titleVisible: false, paths: [require('@/assets/audio/music/美丽的草原我的家/1.mp3'), require('@/assets/audio/music/美丽的草原我的家/2.mp3')] },
            { title: '曲目20', realTitle: '天堂', titleVisible: false, paths: [require('@/assets/audio/music/天堂/1.mp3'), require('@/assets/audio/music/天堂/2.mp3')] },
            { title: '曲目21', realTitle: '踏浪', titleVisible: false, paths: [require('@/assets/audio/music/踏浪/1.mp3'), require('@/assets/audio/music/踏浪/2.mp3')] },
            { title: '曲目22', realTitle: '鸿雁', titleVisible: false, paths: [require('@/assets/audio/music/鸿雁/1.mp3'), require('@/assets/audio/music/鸿雁/2.mp3')] },
            { title: '曲目23', realTitle: '酒干倘卖无', titleVisible: false, paths: [require('@/assets/audio/music/酒干倘卖无/1.mp3'), require('@/assets/audio/music/酒干倘卖无/2.mp3')] },
            { title: '曲目24', realTitle: '乌兰巴托之夜', titleVisible: false, paths: [require('@/assets/audio/music/乌兰巴托之夜/1.mp3'), require('@/assets/audio/music/乌兰巴托之夜/2.mp3')] },
            { title: '曲目25', realTitle: '铁血丹心', titleVisible: false, paths: [require('@/assets/audio/music/铁血丹心/1.mp3'), require('@/assets/audio/music/铁血丹心/2.mp3')] },
            { title: '曲目26', realTitle: '父亲的草原母亲的河', titleVisible: false, paths: [require('@/assets/audio/music/父亲的草原母亲的河/1.mp3'), require('@/assets/audio/music/父亲的草原母亲的河/2.mp3')] },
            { title: '曲目27', realTitle: '预谋', titleVisible: false, paths: [require('@/assets/audio/music/预谋/1.mp3'), require('@/assets/audio/music/预谋/2.mp3')] },
            { title: '曲目28', realTitle: '敖包相会', titleVisible: false, paths: [require('@/assets/audio/music/敖包相会/1.mp3'), require('@/assets/audio/music/敖包相会/2.mp3')] },
            { title: '曲目29', realTitle: '剑心', titleVisible: false, paths: [require('@/assets/audio/music/剑心/1.mp3'), require('@/assets/audio/music/剑心/2.mp3')] },
            { title: '曲目30', realTitle: '天下最美', titleVisible: false, paths: [require('@/assets/audio/music/天下最美/1.mp3'), require('@/assets/audio/music/天下最美/2.mp3')] },
            { title: '曲目31', realTitle: '长歌一曲', titleVisible: false, paths: [require('@/assets/audio/music/长歌一曲/1.mp3'), require('@/assets/audio/music/长歌一曲/2.mp3')] },
            { title: '曲目32', realTitle: '站在草原望北京', titleVisible: false, paths: [require('@/assets/audio/music/站在草原望北京/1.mp3'), require('@/assets/audio/music/站在草原望北京/2.mp3')] },
            { title: '曲目33', realTitle: '秋意浓/李香兰', titleVisible: false, paths: [require('@/assets/audio/music/秋意浓/1.mp3'), require('@/assets/audio/music/秋意浓/2.mp3')] },
            { title: '曲目34', realTitle: '闯将令', titleVisible: false, paths: [require('@/assets/audio/music/闯将令/1.mp3'), require('@/assets/audio/music/闯将令/2.mp3')] },
            { title: '曲目35', realTitle: '小刀会序曲', titleVisible: false, paths: [require('@/assets/audio/music/小刀会序曲/1.mp3'), require('@/assets/audio/music/小刀会序曲/2.mp3')] },
          ]
        },
        {
          name: '拼字游戏',
          detail: '人数：6人以上/轮 \n 屏幕上会放出一个偏旁部首/字，玩家需要依次说出包含 偏旁部首/字 的一个不同的字，未能说出的玩家被淘汰。出现淘汰者后需要更换新的 偏旁部首/字 再开始作答，直到剩余两名玩家为止，这两名玩家获胜并领取奖励。(ﾒ｀ﾛ´)/ \n 奖励：纸抽/牙膏/毛巾',
          type: 'bigFont',
          content: ['冫', '讠', '刂', '又', '亻', '阝', '廴', '氵', '彡', '忄', '宀', '广', '辶', '扌', '艹', '彳', '犭', '饣', '纟', '灬', '土', '弓', '女', '山', '马', '云', '合', '力', '禾', '贝', '音', '吉', '羽', '文', '鱼', '平', '安', '本', '令', '白', '主'],
        },
        {
          name: '挑战大肺王',
          detail: '人数：不限 \n 规则：屏幕上会提供一段文字，玩家需要一口气尽量多的说出这段话，中间不能有停顿或换气。说出最多字数的人成为擂主，其他人也可以挑战擂主。每人都可重复挑战，当无人挑战或无人能打破记录时，擂主获胜并领取奖励。挑战者们，努力成为大肺王擂主吧！(ﾒ｀ﾛ´)/ \n 奖励：纸抽/牙膏/毛巾',
          content: [
            '舜发于畎亩之中，傅说举于版筑之间，胶鬲举于鱼盐之中，管夷吾举于士，孙叔敖举于海，百里奚举于市。 故天将降大任于斯人也，必先苦其心志，劳其筋骨，饿其体肤，空乏其身，行拂乱其所为，所以动心忍性，曾益其所不能。',
            '万钟则不辩礼义而受之，万钟于我何加焉！为宫室之美，妻妾之奉，所识穷乏者得我欤？乡为身死而不受，今为宫室之美为之；乡为身死而不受，今为妻妾之奉为之；乡为身死而不受，今为所识穷乏者得我而为之：是亦不可以已乎？此之谓失其本心。',
            '记得有这样一句话，被很多人作为签名：予人玫瑰，手有余香。当我们学会点亮心里的那盏灯时，前途就不会被黑暗阻挡。也只有我们学会点亮那盏灯，才会在前方还是黑暗的时候，能够照亮同行的人。伸手之间，你会传递温暖；伸手之间，你会挽回生命；伸手之间，你会让人生更加美好。',
            '水陆草木之花，可爱者甚蕃。晋陶渊明独爱菊。自李唐来，世人盛爱牡丹。予独爱莲之出淤泥而不染，濯清涟而不妖，中通外直，不蔓不枝，香远益清，亭亭净植，可远观而不可亵玩焉。 予谓菊，花之隐逸者也；牡丹，花之富贵者也；莲，花之君子者也。噫！菊之爱，陶后鲜有闻。莲之爱，同予者何人？牡丹之爱，宜乎众矣！',
            '生活中的美并非一定是精雕细琢或惊天动地的.，它往往体现在看似平常的小事中，给你感动，让你温暖:助人为乐是一种美，对人和善是一种美，诚实守信是一种美，积极向上是一种美，不屈不挠也是一种美……这时，你会发现，美其实无处不在:一种精神，一个场景，一幅画面，一句唠叨却温暖的话语，甚至是一双手，一个背影……都会让你怦然心动，感动不已。 ',
            '那份新鲜感好奇心早被一日日重复的沉辉和暮霭雕刻得平淡无奇。曾经想把生命的每一分钟都过得灿烂而辉煌，即使面对偶尔的失意，也总想张扬出一番与众不同。但是当双重打击骤然降临时，我一向相当自负的世界几乎彻底坍塌，等从重重叠叠的失败中爬起，在回首的瞬间，才知道光阴已经在我无知的岁月中溜走了好久，才知道风花雪月的诱惑在我的年龄中早已消失，才觉得走过的路很短又很长。',
            '落日的余辉懒洋洋的爬过山那洁白而光滑的肌肤；暖暖地照在这片静谧的大地,天边的云儿飘过,像是在追随同伴的脚步；温蓝如玉般的湖水缓缓地流着,湖边横斜着几尾小舟,隐隐约约有几点渔火在闪耀.也许景色太寂寥时,心情便会唱歌,歌声伴着湖水,要将我带到那令人怀念的往昔岁月,带着点神伤,可是当我转头想要离开的时候,看到了山的另一头,那是太阳再次升起是地方啊,也许明天春天就会来临! ',
            '人生的旅途中，前面还有无数个日子需要别无选择地去面对。风雨也好，艳阳也好，只要在曲曲折折的行程中，静守着一份平淡，挥洒着一腔执着，不妄自尊大，不自欺欺人，不蜚短流长，不急功近利，不孜孜于荣华富贵，不耿耿于功名利禄。”得失俱忘看天上云卷云舒，宠辱不惊任庭前花开花谢。”，管他丰碑小草，高山低谷，只要用心地挥洒，真诚地惜爱。那么，我们内心的丰赡适意，会一点一滴地丰满着你的昨天今天还有明天。 ',
            '与惊天动地的奇迹相比，举手之劳微不足道，但由小见大，与微之外见精神，古人云：“不积跬步，无以至千里”，一个人要成功，不仅要干大事，还要从小事做起，不仅要做好份内的事，而且还得做好份外的事，细节决定成败，只有注意细节的人，才能全心投入，对待工作严谨认真，习惯举手之劳之人，才是胸怀宽大，能替他人所想，把他人放在心上的人，社会需要温暖，人与人之间需要真诚，人人都献出一点爱，都来点举手之劳，社会发展将更美好。',
            '君不见，黄河之水天上来，奔流到海不复回。 君不见，高堂明镜悲白发，朝如青丝暮成雪。 人生得意须尽欢，莫使金樽空对月。 天生我材必有用，千金散尽还复来。 烹羊宰牛且为乐，会须一饮三百杯。 岑夫子，丹丘生，将进酒，杯莫停。 与君歌一曲，请君为我倾耳听。 钟鼓馔玉不足贵，但愿长醉不愿醒。 古来圣贤皆寂寞，惟有饮者留其名。 陈王昔时宴平乐，斗酒十千恣欢谑。 主人何为言少钱，径须沽取对君酌。 五花马，千金裘，呼儿将出换美酒，与尔同销万古愁。',
            '夫人之相与，俯仰一世，或取诸怀抱，悟言一室之内；或因寄所托，放浪形骸之外。虽趣舍万殊，静躁不同，当其欣于所遇，暂得于己，快然自足，曾不知老之将至。及其所之既倦，情随事迁，感慨系之矣。向之所欣，俯仰之间，已为陈迹，犹不能不以之兴怀。况修短随化，终期于尽。古人云：“死生亦大矣。”岂不痛哉！ 每览昔人兴感之由，若合一契，未尝不临文嗟悼，不能喻之于怀。固知一死生为虚诞，齐彭殇为妄作。后之视今，亦犹今之视昔。悲夫！故列叙时人，录其所述，虽世殊事异，所以兴怀，其致一也。后之览者，亦将有感于斯文。',
            '君子曰：学不可以已。 青，取之于蓝而青于蓝；冰，水为之而寒于水。木直中绳，輮以为轮，其曲中规。虽有槁暴，不复挺者，輮使之然也。故木受绳则直，金就砺则利，君子博学而日参省乎己，则知明而行无过矣。故不登高山，不知天之高也；不临深溪，不知地之厚也；不闻先王之遗言，不知学问之大也。 吾尝终日而思矣，不如须臾之所学也；吾尝跂而望矣，不如登高之博见也。登高而招，臂非加长也，而见者远；顺风而呼，声非加疾也，而闻者彰。假舆马者，非利足也，而致千里；假舟楫者，非能水也，而绝江河。君子生非异也，善假于物也。',
            '积土成山，风雨兴焉；积水成渊，蛟龙生焉；积善成德，而神明自得，圣心备焉。故不积跬步，无以至千里；不积小流，无以成江海。骐骥一跃，不能十步；驽马十驾，功在不舍。锲而舍之，朽木不折；锲而不舍，金石可镂。蚓无爪牙之利，筋骨之强，上食埃土，下饮黄泉，用心一也。蟹六跪而二螯，非蛇鳝之穴无可寄托者，用心躁也。 和九年，岁在癸丑，暮春之初，会于会稽山阴之兰亭，修禊事也。群贤毕至，少长咸集。此地有崇山峻岭，茂林修竹；又有清流激湍，映带左右，引以为流觞曲水，列坐其次。虽无丝竹管弦之盛，一觞一咏，亦足以畅叙幽情。是日也， 天朗气清，惠风和畅，仰观宇宙之大，俯察品类之盛，所以游目骋怀，足以极视听之娱，信可乐也。',
            '人恒过，然后能改，困于心，衡于虑，而后作，征于色，发于声，而后喻。入则无法家拂士，出则无敌国外患者，国恒亡。 然后知生于忧患，而死于安乐也。 鱼，我所欲也，熊掌，亦我所欲也，二者不可得兼，舍鱼而取熊掌者也。生，亦我所欲也，义，亦我所欲也，二者不可得兼，舍生而取义者也。 生亦我所欲，所欲有甚于生者，故不为苟得也。死亦我所恶，所恶有甚于死者，故患有所不辟也。如使人之所欲莫甚于生，则凡可以得生者何不用也？使人之所恶莫甚于死者，则凡可以辟患者何不为也？由是则生而有不用也；由是则可以辟患而有不为也。是故所欲有甚于生者，所恶有甚于死者。非独贤者有是心也，人皆有之，贤者能勿丧耳。 一箪食，一豆羹，得之则生，弗得则死。呼尔而与之，行道之人弗受；蹴尔而与之，乞人不屑也。',
            '天生我材必有用，余以为然。 上帝造我，为何？上帝言于吾曰：“有，吾造人，在于人之德、人之能。天生我材必有用！”余醒…… 人有才，属天赐，若后得才，乃勤奋焉。世有伯乐，亦有诸多千里马伯乐相马，时时发生。若有才，无人知晓，勿恼，能寻道而见也 ，终能寻得伯乐助己。 且听我以白话而道之。 社会飞速发展，人才辈出，但仍有人怀才不遇，或有才不去展现。 我个人认为，人人都有自己的才能，或多或少、或大或小，这都是属于自己的本领．但有些人缺少了机遇，缺少了胆量，不敢去为自己而拼，尽管努力，却才美不外现，他们需要对的时间，他们需要“伯乐”。 其实包括我身边的很多人，有的不是怀才不遇，而是深藏不露，所谓的“高人”，就指他们，只是有很多人都不知道。 但有才能却无法施展的各位同胞们，记住“天生我材必有用”这句话，相信总有一天，会有“伯乐”登门拜访! ',
            '晋太元中，武陵人捕鱼为业。缘溪行，忘路之远近。忽逢桃花林，夹岸数百步，中无杂树，芳草鲜美，落英缤纷，渔人甚异之。复前行，欲穷其林。 林尽水源，便得一山，山有小口，仿佛若有光。便舍船，从口入。初极狭，才通人。复行数十步，豁然开朗。土地平旷，屋舍俨然，有良田美池桑竹之属。阡陌交通，鸡犬相闻。其中往来种作，男女衣着，悉如外人。黄发垂髫，并怡然自乐。 见渔人，乃大惊，问所从来。具答之。便要还家，设酒杀鸡作食。村中闻有此人，咸来问讯。自云先世避秦时乱，率妻子邑人来此绝境，不复出焉，遂与外人间隔。问今是何世，乃不知有汉，无论魏晋。此人一一为具言所闻，皆叹惋。余人各复延至其家，皆出酒食。停数日，辞去。此中人语云：“不足为外人道也” 。既出，得其船，便扶向路，处处志之。及郡下，诣太守，说如此。太守即遣人随其往，寻向所志，遂迷，不复得路。 南阳刘子骥，高尚士也，闻之，欣然规往。未果，寻病终，后遂无问津者。 ',
          ]
        },
        {
          name: '手舞足蹈',
          detail: '人数：8人/轮 \n 规则：所有玩家站成一排，玩家需要在播放BGM的同时，统一听主持人口令做出动作，做错动作或反应最慢的玩家淘汰，剩下的最后2名玩家获得胜利并领取奖励。 \n 口令：（示例）右手抬起来/右手放回去/左手抬起来/左手放回去/右脚抬起来/右脚放回去/左脚抬起来/左脚放回去 \n 奖励：洗衣液/洗头膏'
        },
        {
          name: '真假难辨',
          detail: '人数：4人/轮 \n 规则：选择4人上场为一个队伍，每轮会有一种特殊食物，屏幕上会展示本轮的特殊食物的名字，玩家可选择一人食用特殊食物，其余3人食用普通食物。4人食用后全体观众可观察玩家反应判断，期间可以提问或者要求，最终投票决定找出食用了特殊食物的人。成功找出则玩家失败，找出失败则玩家获胜。玩家3轮内得两胜则挑战成功领取奖励，否则挑战失败接受严峻的惩罚。(屮｀∀´)屮。 \n 奖励：洗衣液/洗头膏'
        },
        {
          name: '驿站传书',
          detail: '人数：4～6人/轮 \n 规则：选择8～12人（偶数）分为A、B两队，每队4～6人。两个队伍轮流上场，上场队伍站成排，全部朝向一侧。首先让第一个玩家转过身，主持人会把词语给该玩家看，首位玩家准备好时游戏开始，玩家仅可通过动作（不可通过声音传递信息）传递给下一个人，传递到最后一人猜词，猜对则本队积一分，3到5轮后得分高的队伍获胜并领取奖励。 \n 奖励：纸抽/牙膏/毛巾'
        },
        {
          name: '是或不是',
          detail: '人数：4～6人/轮 \n 规则：全部玩家上场后会没人发一个词语卡（不可查看）并固定在头上，游戏开始从第一个人开始轮流提问（如：我这个是物品吗？），其他玩家只能回答是/不是，每人每次可以问5个问题（猜测答案算占用一次），问完则传给下一名玩家提问。猜对词语的玩家可下场，最后一个未能猜对的要接受严峻的惩罚。(屮｀∀´)屮'
        },
        {
          name: '你画我猜',
          detail: '人数：全体 \n 规则：每桌玩家为一队，每桌选出一人作为猜词人上台，其余人作为作画人。每桌主持人会提供一张画纸，纸上会写着题目。在所有玩家准备好后，主持人宣布开始，每人限时10秒作画，到时间后需要传递给下一个人继续作画，直到全桌人全部画完。最后给本桌的猜词人看画猜词，猜错的队伍需要接受严峻的惩罚。(｀∀´)Ψ'
        }
      ]
    }
  },

  methods: {
  }
}
</script>

<style scoped>
.background {
  min-height: 100vh;
  width: 100%;
  background-image: url('../../assets/images/background_2024.jpg');
  background-repeat: no-repeat;
  background-size: 100vw 100%;
  background-position: center top;
  margin: 0px;
  padding: 0px 0px 20px 0px;
}

.container {
  width: calc(100% - 120px);
  padding: 200px 0px 20px 0px;
}

.label-first-layer {
  margin: 0px auto;
  border: 4px solid #FFD86D;
  padding: 3px;
}

.label-second-layer {
  border: 1px solid #FFD86D;
  padding: 2px;
}

.label-third-layer {
  background-color: #D92411;
  line-height: 20px;
}

.label-text {
  width: 85px;
  font-size: 14px;
  font-weight: 400;
  color: #FFD86D;
  text-align: center;
}

/* 定义滚动条样式 */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
</style>
